import { Component, ViewChild } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AddressInformationFormGroupType } from 'src/app/models/utils/forms/address-information-form';
import { regex } from 'src/app/utils/constants';
import {
    BusinessDetailsFormGroupType,
    PersonalInformationFormGroupType,
    SecurityFormGroupType,
} from './models/form-types';
import { PersonalInformationComponent } from './stepper/personal-information/personal-information.component';

@Component({
    selector: 'app-apply-for-cargo-facility',
    templateUrl: './apply-for-cargo-facility.component.html',
    styleUrl: './apply-for-cargo-facility.component.scss',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ApplyForCargoFacilityComponent {
    @ViewChild('personalInformation', { static: true }) personalInformation: PersonalInformationComponent;
    @ViewChild('stepper') stepper!: MatStepper;
    public personalInformationForm: PersonalInformationFormGroupType;
    public businessDetailsForm: BusinessDetailsFormGroupType;
    public addressForm: AddressInformationFormGroupType;
    public securityForm: SecurityFormGroupType;

    constructor(private fb: FormBuilder) {
        this.personalInformationForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(regex.email)]],
            phoneForm: this.fb.group({
                code: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
                phone: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
            }),
        });

        this.businessDetailsForm = this.fb.group({
            businessName: ['', [Validators.required]],
            sameAsMyName: [false, []],
            ein: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
            addressForm: <AddressInformationFormGroupType>this.fb.group({
                street: ['', [Validators.required]],
                street2: ['', []],
                city: ['', [Validators.required]],
                state: ['', [Validators.required]],
                country: ['', [Validators.required]],
                zipCode: ['', [Validators.required]],
            }),
        });

        this.securityForm = this.fb.group({
            password: ['', [Validators.required]],
            acceptedTerms: [false, [Validators.requiredTrue]],
        });
    }
}
