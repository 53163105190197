<form [formGroup]="parentForm">
    <div class="sub-title bt-5">ADDRESS INFORMATION</div>
    <div>
        <lib-address-information
            #parentFormTemplate
            class="mt-4 d-block"
            [addressForm]="parentForm.controls['addressForm']"
        >
        </lib-address-information>
    </div>

    <div class="sub-title">BUSINESS INFORMATION</div>
    <mat-form-field id="business-name" appearance="outline" class="d-block pb-0 w-100 mt-2">
        <mat-label>Business Name</mat-label>
        <input id="business-name-input" matInput formControlName="businessName" placeholder="Business Name" />
        <mat-error>
            <app-invalid-field [field]="'businessName'" [messageList]="errorList"> </app-invalid-field>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100 mt-2">
        <mat-label>EIN (Employer Identification Number)</mat-label>
        <input id="ein" matInput formControlName="ein" placeholder="XX-XXXXXXX" mask="00-0000000" />
        <mat-error>
            <app-invalid-field [field]="'ein'" [messageList]="errorList"> </app-invalid-field>
        </mat-error>
    </mat-form-field>

    <div class="mb-5 text-end">
        <a id="w9-link" class="w9-link" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank"
            >Need a W9 Form?</a
        >
    </div>
    <!-- <app-file-upload
        class="file-upload"
        [whiteListFile]="['.pdf']"
        [subtitle]="''"
        [title]="'Upload W9'"
        (filesAdded)="filesAdded($event, 'file1')"
        [loading]="uploading"
        [isDisabled]="false"
    ></app-file-upload>

    <app-file-upload
        class="file-upload mb-4"
        [whiteListFile]="['.pdf']"
        [subtitle]="''"
        [title]="'Upload Void Check'"
        (filesAdded)="filesAdded($event, 'file2')"
        [loading]="uploading"
        [isDisabled]="false"
    ></app-file-upload> -->

    <app-next-previous-buttons [previousText]="'BACK'" [disabled]="!parentForm.valid"></app-next-previous-buttons>
</form>
