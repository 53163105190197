import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplyForCargoFacilityComponent } from './apply-for-cargo-facility.component';
import { RequestReceivedComponent } from './request-received/request-received.component';

// COMPONENTS

const routes: Routes = [
    {
        path: '',
        component: ApplyForCargoFacilityComponent,
        data: { breadcrumb: { alias: 'apply-for-cargo-facility' } },
    },
    {
        path: 'success',
        component: RequestReceivedComponent,
        data: { breadcrumb: { alias: 'success' } },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ApplyForCargoFacilityRoutingModule {}
