<div class="custom-container">
    <img
        class="logo mt-5 mb-5 mx-auto d-block"
        src="assets/images/sprintpay-horizontal-logo.svg"
        alt="Cargo Sprint Logo"
    />
    <div class="mt-5 mb-3 header text-center">Payee Application</div>
    <div class="header-description mb-5">
        To make the most of our platform and unlock its full benefits, please take a moment to provide the following
        information.
    </div>

    <mat-stepper linear labelPosition="end" #stepper>
        <mat-step
            [editable]="true"
            errorMessage="Personal information is needed"
            [stepControl]="personalInformationForm"
        >
            <ng-template matStepLabel>Personal Information</ng-template>
            <ng-template matStepContent>
                <app-personal-information
                    [formGroup]="personalInformationForm"
                    [stepper]="stepper"
                ></app-personal-information>
            </ng-template>
        </mat-step>

        <mat-step
            [editable]="true"
            errorMessage="Business Details information is needed"
            [stepControl]="businessDetailsForm"
        >
            <ng-template matStepLabel>Business Information</ng-template>
            <ng-template matStepContent>
                <app-business-details [formGroup]="businessDetailsForm"></app-business-details>
            </ng-template>
        </mat-step>

        <mat-step [editable]="true" errorMessage="Security information is needed" [stepControl]="securityForm">
            <ng-template matStepLabel>Security</ng-template>
            <ng-template matStepContent>
                <app-security-step
                    [formGroup]="securityForm"
                    [email]="personalInformationForm.controls['email'].value"
                ></app-security-step>
            </ng-template>
        </mat-step>
    </mat-stepper>

    <div class="text-center mb-5">
        Do you already have an account?
        <a class="signin-link" [routerLink]="['/login']">Sign in</a>
    </div>
</div>

<div class="text-end m-5">
    <img src="./assets/images/powered-by-cargosprint.svg" alt="Powered by CargoSprint" />
</div>
