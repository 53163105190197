import { Component } from '@angular/core';

@Component({
    selector: 'app-request-received',
    templateUrl: './request-received.component.html',
    styleUrl: './request-received.component.scss',
})
export class RequestReceivedComponent {
    constructor() {}
}
