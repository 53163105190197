<form [formGroup]="parentForm">
    <mat-form-field appearance="outline" class="w-100 mt-2">
        <mat-label>First Name</mat-label>

        <input matInput formControlName="firstName" placeholder="First Name" />
        <mat-error>
            <app-invalid-field [field]="'firstName'" [messageList]="errorList"> </app-invalid-field>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" placeholder="Last Name" />
        <mat-error>
            <app-invalid-field [field]="'lastName'" [messageList]="errorList"> </app-invalid-field>
        </mat-error>
    </mat-form-field>

    <lib-phone-input [isFormEnabled]="true"></lib-phone-input>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="john.doe@example.com" />
        <mat-error>
            <app-invalid-field [field]="'email'" [messageList]="errorList"> </app-invalid-field>
        </mat-error>
    </mat-form-field>

    <div class="email-container mb-5 p-4">
        <p class="two-factor-auth mb-2">TWO FACTOR AUTHENTICATION</p>
        <p class="two-factor-auth-description mb-2">
            This is an extra layer of security that helps protect your account by requering a form of verification
            before granting access. It adds an additional step to the usual username and password login process.
        </p>

        <p class="email-value pt-2">{{ parentForm.controls['email'].value }}</p>
        <p class="email-legend">We will send an email with a code to your email address.</p>
    </div>

    <app-next-previous-buttons
        [disabled]="parentForm.invalid"
        [previousText]="'Cancel'"
        [customPreviousHandler]="true"
        [customNextHandler]="true"
        (previous)="cancel()"
        (next)="next()"
    ></app-next-previous-buttons>
</form>

<ng-template #authenticationFlux>
    <app-authentication-flux
        [messageAuthentication]="messageAuthentication"
        [module]="module"
    ></app-authentication-flux>
</ng-template>
