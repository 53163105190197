import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ErrorInputFieldComponent, FileException } from '@cargos/sprintpay-models';
import { Observable, forkJoin, take } from 'rxjs';
import { FileRequestService } from 'src/app/services/file-handler.service';
import { FileHandlerService } from 'src/app/standalone-components/file-handler-dynamic/services/file.service';
import Swal from 'sweetalert2';
import { BusinessDetailsFormGroupType } from '../../models/form-types';

@Component({
    selector: 'app-business-details',
    templateUrl: './business-details.component.html',
    styleUrl: './business-details.component.scss',
})
export class BusinessDetailsComponent implements OnInit {
    public parentForm: BusinessDetailsFormGroupType;
    public uploading: boolean = false;
    public filesUploaded: string[] = [];
    public errorList: ErrorInputFieldComponent[];

    constructor(
        private fileRequestService: FileRequestService,
        private fileHandlerService: FileHandlerService,
        private controlContainer: ControlContainer
    ) {}

    ngOnInit(): void {
        this.parentForm = this.controlContainer.control as BusinessDetailsFormGroupType;

        this.errorList = [
            {
                code: 'required',
                message: `Required field`,
            },
            {
                code: 'pattern',
                message: `Invalid input`,
            },
        ];
    }

    filesAdded(files: FormData[], file: string): void {
        if (this.uploading) {
            return;
        }

        this.uploading = true;

        this.uploadFiles(files)
            .pipe(take(1))
            .subscribe({
                next: (locationPaths: string[]) => {
                    this.uploading = false;
                    const newFileNames: string[] = [...locationPaths, ...this.filesUploaded];

                    this.fileHandlerService.setPathFiles(newFileNames);
                },
                error: (error: FileException) => {
                    this.uploading = false;

                    Swal.fire({
                        title: error.title,
                        text: error.description,
                        icon: 'warning',
                    });
                },
            });
    }

    uploadFiles(files: FormData[]): Observable<string[]> {
        return forkJoin(files.map((file) => this.fileRequestService.uploadFile(file).pipe(take(1))));
    }
}
