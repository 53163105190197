<form [formGroup]="parentForm">
    <div class="mt-2">
        <app-form-password (eventOnPassword)="onSetPassword($event)"></app-form-password>

        <div id="submit-text" class="submit-text">
            By submitting this form, I confirm that the information provided is accurate and complete. I certify that I
            am an authorized representative of the company named above and have the authority to submit this application
            and supporting documents on its behalf.
        </div>

        <div class="my-3">
            <mat-checkbox id="accept-terms" formControlName="acceptedTerms"></mat-checkbox>
            I accept CargoSprint’s
            <a id="terms-of-use" href="https://cargosprint.com/legal/terms-of-use/sprintpay/" target="_blank"
                >Terms of Use</a
            >
            &
            <a id="privacy-policy" href="https://cargosprint.com/legal/privacy-policy/" target="_blank"
                >Privacy Policy</a
            >
        </div>

        <app-next-previous-buttons
            [disabled]="!parentForm.valid"
            [customNextHandler]="true"
            (next)="completeProcess()"
        ></app-next-previous-buttons>
    </div>
</form>
