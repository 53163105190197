import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { Payment } from 'src/app/models/payments/payment.model';
import { PaymentFluxService } from '../../../services/utils/payment-flux.service';
import { CustomerService } from '../../../services/utils/user/customer-handler.service';

@Component({
    selector: 'app-emotrans-customer-reference',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './emotrans-customer-reference.component.html',
})
export class EmotransCustomerReferenceComponent implements OnInit {
    public hasExternalLookup: boolean = false;
    public id: string;
    public isStartingLevel: boolean;
    private currentPayment: Payment | null;

    @Input() paymentDetail: Payment | null;

    get isVisibleEditButton(): boolean {
        return (
            ((this.paymentDetail?.approvalStatus === 'PENDING' &&
                this.paymentDetail.uniqueRequestor &&
                this.isStartingLevel &&
                !this.paymentDetail.ownerCanPay &&
                this.paymentDetail.sameCustomerAndRequestor) ||
                (this.paymentDetail?.approvalStatus === 'REJECTED' &&
                    this.isStartingLevel &&
                    this.paymentDetail.sameCustomerAndRequestor) ||
                (this.isStartingLevel && this.paymentDetail?.id === null) ||
                (this.isStartingLevel && this.paymentDetail?.id !== null && !!this.paymentDetail?.fromCart)) &&
            !!this.paymentDetail.sprintPaySource &&
            !this.hasExternalLookup
        );
    }

    constructor(
        private customerService: CustomerService,
        private router: Router,
        private paymentFluxService: PaymentFluxService
    ) {
        this.id = '';
        this.currentPayment = this.paymentFluxService.getCurrentPayment();
        this.isStartingLevel = this.customerService.getStartingLevel();
    }

    ngOnInit(): void {
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this.currentPayment !== undefined && this.currentPayment?.id
                  ? this.currentPayment.id
                  : '';
        this.hasExternalLookup =
            this.paymentDetail && this.paymentDetail.externalLookup
                ? this.paymentDetail.externalLookup
                : this.currentPayment && this.currentPayment.facility && this.currentPayment.facility.externalLookup
                  ? this.currentPayment.facility.externalLookup
                  : false;
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest(): void {
        if (this.paymentDetail) {
            this.paymentDetail.step = 0;
        }
        this.paymentFluxService.setAndSaveCurrentPayment(Payment.fromJson({ ...this.paymentDetail }));
        this.router.navigate(['/admin/facilityPayments/newPayment/flux']);
    }
}
