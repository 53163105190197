import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityFormGroupType } from '../../models/form-types';

@Component({
    selector: 'app-security-step',
    templateUrl: './security-step.component.html',
    styleUrl: './security-step.component.scss',
})
export class SecurityStepComponent implements OnInit {
    @Input() email: string | null;
    public parentForm: SecurityFormGroupType;

    constructor(
        private controlContainer: ControlContainer,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.parentForm = this.controlContainer.control as SecurityFormGroupType;
    }

    completeProcess(): void {
        this.router.navigate(['signup/payee-signup/success']);
    }

    onSetPassword(password: string): void {
        this.parentForm.controls['password'].setValue(password);
    }
}
