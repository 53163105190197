import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ErrorInputFieldComponent } from '@cargos/sprintpay-models';
import { PhoneInputComponent } from '@cargos/sprintpay-ui';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationFluxComponent } from 'src/app/modules/two-factor/authentication-flux/authentication-flux.component';
import {
    ModuleMFAEnum,
    ResponseMFA,
    TypeModuleMFA,
} from 'src/app/modules/two-factor/verification-code/models/two-factor-models';
import { HandlerVerificationService } from 'src/app/services/handler-verification.service';
import { PersonalInformationFormGroupType } from '../../models/form-types';

@Component({
    selector: 'app-personal-information',
    templateUrl: './personal-information.component.html',
    styleUrl: './personal-information.component.scss',
})
export class PersonalInformationComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() stepper!: MatStepper;
    @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
    @ViewChild(PhoneInputComponent) phoneInputComponent: PhoneInputComponent;
    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<AuthenticationFluxComponent>;
    public parentForm: PersonalInformationFormGroupType;
    public errorList: ErrorInputFieldComponent[];
    private unsubscribe$: Subject<void> = new Subject<void>();
    public messageAuthentication: string =
        'This is an extra layer of security that helps protect your account by requiring a form of verification before granting access.';
    public module: TypeModuleMFA = ModuleMFAEnum.EMAIL_VALIDATION;

    constructor(
        private controlContainer: ControlContainer,
        private matDialog: MatDialog,
        private handlerVerificationService: HandlerVerificationService
    ) {}

    ngOnInit(): void {
        this.parentForm = this.controlContainer.control as PersonalInformationFormGroupType;

        this.errorList = [
            {
                code: 'required',
                message: `Required field`,
            },
            {
                code: 'pattern',
                message: `Oops. The email address was malformed! Please try again`,
            },
        ];
    }

    ngAfterViewInit(): void {
        this.parentForm.controls['phoneForm'].controls['code'].setValue(
            this.phoneInputComponent?.phoneForm.controls['code'].value
        );

        this.phoneInputComponent?.phoneForm
            .get('code')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((code) => {
                this.parentForm.controls['phoneForm'].controls['code'].setValue(code);
            });

        this.phoneInputComponent?.phoneForm
            .get('phone')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((code) => {
                this.parentForm.controls['phoneForm'].controls['phone'].setValue(code);
            });
    }

    cancel(): void {
        this.cancelEvent.emit(true);
    }

    next(): void {
        this.module = ModuleMFAEnum.EMAIL_VALIDATION;

        this.matDialog.open(this.authenticationFlux as ComponentType<AuthenticationFluxComponent>, {
            id: 'authenticationFlux',
            disableClose: true,
            width: '55em',
            data: {
                email: this.parentForm?.controls['email']?.value,
                activationUuid: this.handlerVerificationService.instant_activationUuid,
                accountVerifiedHandler: (event: ResponseMFA) => {
                    if (event.isVerified) {
                        this.stepper.next();
                    }
                },
            },
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
