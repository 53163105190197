<div class="custom-container text-center">
    <img class="my-3" src="assets/images/sprintpay-horizontal-logo.svg" alt="SprintPay logo" /><br />
    <img class="my-3" src="assets/images/icons/icon-success.svg" alt="success" />

    <div id="application-successful" class="main-title mb-3">Your application request was successful!</div>
    <div id="application-successful-description" class="description mb-3">
        Thank you for submitting your information. Our team is reviewing your details and documents now. If any
        additional documents are needed,
        <strong>we will contact you within two hours (Mon–Fri, 8am–5pm CST).</strong> Otherwise, you will receive
        confirmation and <strong>next steps within 24 hours.</strong>
    </div>
    <div class="description mb-3">
        If you have questions in the meantime, please reach out to
        <a class="mail-to" href="mailto:sales@cargosprint.com">sales&#64;cargosprint.com</a>.
    </div>
    <button id="go-to-cargosprint" class="go-button" onclick="window.location.href='https://cargosprint.com'">
        GO TO CARGOSPRINT
    </button>
</div>
