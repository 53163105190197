import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddressInformationComponent, PhoneInputComponent } from '@cargos/sprintpay-ui';
import { TwoFactorModule } from 'src/app/modules/two-factor/two-factor.module';
import { InvalidFieldComponent } from 'src/app/shared/components/invalid-field/invalid-field.component';
import { AwbSearchModule } from 'src/app/shared/modules/awb-search/awb-search.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DynamicFileComponent } from 'src/app/standalone-components/dynamic-form-cf/dynamic-field/dynamic-file/dynamic-file.component';
import { FileUploadComponent } from 'src/app/standalone-components/file-handler-dynamic/file-uploader/file-upload.component';
import { FileHandlerService } from 'src/app/standalone-components/file-handler-dynamic/services/file.service';
import { FormPasswordComponent } from 'src/app/standalone-components/form-password/form-password.component';
import { UploadFileComponent } from 'src/app/standalone-components/upload-file/upload-file.component';
import { ValidateEmailComponent } from 'src/app/standalone-components/validate-email/validate-email.component';
import { ApplyForCargoFacilityRoutingModule } from './apply-for-cargo-facility-routing.module';
import { ApplyForCargoFacilityComponent } from './apply-for-cargo-facility.component';
import { RequestReceivedComponent } from './request-received/request-received.component';
import { BusinessDetailsComponent } from './stepper/business-details/business-details.component';
import { NextPreviousButtonsComponent } from './stepper/next-previous-buttons/next-previous-buttons.component';
import { PersonalInformationComponent } from './stepper/personal-information/personal-information.component';
import { SecurityStepComponent } from './stepper/security-step/security-step.component';

@NgModule({
    declarations: [
        ApplyForCargoFacilityComponent,
        RequestReceivedComponent,
        PersonalInformationComponent,
        BusinessDetailsComponent,
        SecurityStepComponent,
        NextPreviousButtonsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        PhoneInputComponent,
        FileUploadComponent,
        AddressInformationComponent,
        FormPasswordComponent,
        ValidateEmailComponent,
        DynamicFileComponent,
        UploadFileComponent,
        AwbSearchModule,
        InvalidFieldComponent,
        ApplyForCargoFacilityRoutingModule,
        TwoFactorModule,
    ],
    providers: [FileHandlerService, FormGroupDirective],
})
export class ApplyForCargoFacilityModule {}
